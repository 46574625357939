<template>
  <div class="uk-width-1-1 uk-height-1-1 bg-white">
    <div class="uk-flex uk-flex-column uk-flex-center">
      <div class="uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-margin-xlarge-top">
        <img
          :src="`${images}/logo-varion.png`"
          alt="logo-varion"
          width="238"
          height="58"
        >
        <h4 class="logo-text uk-margin-medium-bottom">
          Admin Dashboard
        </h4>
      </div>
      <div class="uk-width-1-1 uk-flex uk-flex-center">
        <form
          @submit.prevent="onSubmit"
        >
          <fieldset class="uk-fieldset uk-text-left">
            <h2 class="uk-legend uk-margin-remove">
              Enter Verification Code
            </h2>
            <div class="uk-width-1-1 uk-margin-medium-top uk-margin-medium-bottom">
              <otp-input
                :is-valid="isCodeValid"
                :digits="6"
                type="number"
                placeholder=""
                separate-wrapper-class="separate-wrapper-class"
                separate-input-class="separate-input-class"
                active-input-class="active-input-class"
                @on-complete="onCompleteHandler"
                @on-paste="onPasteHandler"
              >
                <template #errorMessage>
                  <p class="otp-error-text uk-margin-remove-bottom uk-text-center">
                    Verification code is incorrect
                  </p>
                </template>
              </otp-input>
            </div>
            <button
              v-if="!getToggleModalAddEditDiscard.loading"
              class="uk-button uk-button-primary uk-width-1-1"
              type="submit"
            >
              Confirm
            </button>
            <button
              v-else
              class="uk-button uk-button-primary uk-width-1-1"
              type="button"
              disabled
            >
              <div uk-spinner />
            </button>
          </fieldset>
        </form>
      </div>
    </div>
    <modal-auth-info
      :images="images"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '../../utils/constant'
import OtpInput from 'otp-input-vue2'
import ModalAuthInfo from '@/components/globals/modals/ModalAuthInfo'

export default {
  components: {
    OtpInput,
    ModalAuthInfo
  },
  data() {
    return {
      token: '',
      otpCode: '',
      images: PREFIX_IMAGE,
      isCodeValid: true
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'auth/get_toggle_modal_add_edit_discard'
    })
  },
  mounted() {
    if (this.$route?.query?.reset_token) {
      this.token = this.$route?.query?.reset_token
    } else {
      this.token = this.$route?.query?.activation_token
    }
  },
  methods: {
    ...mapActions({
      actionVerificationReset: 'auth/verificationReset',
      actionVerificationActivateUser: 'auth/verificationActivatedUser'
    }),
    ...mapMutations({
      setToggleModalAddEditDiscard: 'auth/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    onCompleteHandler(code){
      this.otpCode = code
    },
    onPasteHandler(code){
      this.otpCode = code
    },
    onSubmit() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.handleEmailVerification()
        }
      })
    },
    handleEmailVerification(){
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading: true})
      // Reset Password //
      if(this.$route?.query?.reset_token){
        this.actionVerificationReset({token: this.token, otp: this.otpCode, new_password: '', confirm_password: ''}).then(result => {
          if(result?.status == 200){
            this.isCodeValid = true
            setTimeout(() => {
              this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading: false})
              this.$router.push({ name: 'ResetPassword', query:{ reset_token: this.token, code: this.otpCode }})
            }, 2000)
          } else {
            this.isCodeValid = false
            this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading: false})
          }
        })
      } else {
        // Activated User //
        this.actionVerificationActivateUser({token: this.token, activate_user_otp: this.otpCode, new_password: '', confirm_password: ''}).then(result => {
          if(result?.status == 200){
            this.isCodeValid = true
            setTimeout(() => {
              this.setToggleModalAddEditDiscard({
                ...this.getToggleModalAddEditDiscard, 
                targetModals: true,  
                title: 'Account successfully activated',
                type: 'activated-user',
                route: 'ResetPassword',
                query1: this.token,
                query2: this.otpCode,
                customTitleBtnOk: 'Okay',
                loading: false
              })
            }, 500)
          } else {
            this.isCodeValid = false
            this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading: false})
          }
        })
      }
      
    }
  }
}
</script>

<style scoped>
  h2 {
    font-weight: 700;
    font-size: 32px;
    color: #000000;
  }
  .timer-countdown {
    font-family: 'interSemiBold';
    font-weight: 500;
    font-size: 20px;
    color: #000000;
  }
  .otp-error-text{
    margin-top: 30px;
    font-family: 'interMedium';
    font-weight: 500;
    font-size: 20px;
    color: #EB5757;
  }
  .vue-otp-input  >>>  .separate-input-class {
    width: 48px;
    height: 48px;
    gap: 14px;
    font-weight: bold;
    font-size: 32px;
    color: #000000;
    background-color: #ffffff;
    border: none;
    border-radius: 0px !important;
    border-bottom: 4px solid #bdbdbd;
    text-align: center;
  }
  .vue-otp-input  >>>  .active-input-class {
    border-color: #2FAC4A;
    transform: none;
  }
</style>
